import React, { createContext, useReducer } from "react"
import { encrypt } from "../utils/security"
import { currentMonth , resetToNextMonth, setDate } from "../utils/storeUtils"
interface DispatchObject {
  type: actions
  payloadString?: string
  index?: number
  payload?: object
}

interface Line {
  description: string
  amount: number
  price: number
}

export interface State {
  isSaved: boolean
  rowKey?: string
  totalPrice?: number
  isPaid?: boolean
  person_title: string
  lines: Line[]
  person_description: string
  closing_letters: string
  payment_description: string
  kwitantieTitle: string
  fromDescription: string
  to_title: string
  to_description: string
  table_header_description: string
  table_header_hour: string
  table_header_amount: string
  table_header_total: string
  kwitantie_number: string
  kwitantieDate?: Date
  number: number
  email?: { emailTo: string; emailFrom: string; message: string }
  lastChange: number
}

export const initialState: State = {
  isSaved: false,
  lines: [{ description: "Uren " + currentMonth(), amount: 1, price: 100 }],
  kwitantieTitle: "Kwitantie",
  fromDescription:
    "[uw voor- en achternaam]<br/> [straatnaam] + [huisnummer]</br>[postcode] [plaats]",
  person_title: "aan",
  person_description:
    "[organistatienaam]<br/> [straatnaam] + [huisnummer]</br>[postcode] [plaats]",
  to_title: "voor",
  to_description: "[contactpersoon]",
  table_header_description: "Omschrijving",
  table_header_hour: "Uren",
  table_header_total: "Totaal",
  table_header_amount: "Stukprijs",
  payment_description: "Graag over maken naar xxx xxx xxx",
  closing_letters: "Bedankt voor de prettige samenwerking.",
  kwitantie_number: "Kwitantie #1",
  number: 1,
  kwitantieDate: setDate(new Date()),
  lastChange: Math.floor(+new Date() / 1000)
}

const reducer = (state: State, dispatchObject: DispatchObject): State => {
  const { type, payloadString, index, payload } = dispatchObject
  const newState = reducerMap(type, state, payloadString, index, payload)
  if (newState && localStorage) {
    localStorage.setItem("state", encrypt(JSON.stringify(newState)))
  }
  return newState
}
const AppContext = createContext<{
  state: State
  dispatch: React.Dispatch<DispatchObject>
}>(initialState as any)
const { Provider } = AppContext

const StateProvider = ({
  children,
  initState,
}: {
  children: any
  initState: State
}) => {
  const [state, dispatch] = useReducer(reducer, initState ?? initialState)
  const value = { state, dispatch }

  return <Provider value={value}>{children}</Provider>
}

export const AppProvider = StateProvider
export const AppConsumer = AppContext.Consumer

export default AppContext

export enum actions {
  load,
  kwitantie_title,
  from_description,
  to_title,
  to_description,
  person_title,
  person_description,
  table_header_description,
  table_header_hour,
  table_header_total,
  table_header_amount,
  payment_description,
  closing_letters,
  lines,
  lines_add,
  line_remove,
  line_description,
  line_amount,
  line_price,
  kwitantie_number,
  reset,
  sendMail,
  flip_is_paid,
  set_row_key,
  set_date,
}

function reducerMap(
  type: actions,
  state: State,
  payloadString?: string,
  index?: number,
  payload?: object
): State {
  if (state) {
    state.isSaved = false;
    state.lastChange = Math.floor(+new Date() / 1000);
  }
  switch (type) {
    case actions.load:
      return { ...(payload as State) }
    case actions.kwitantie_title:
      return { ...state, kwitantieTitle: payloadString }
    case actions.kwitantie_number:
      return { ...state, kwitantie_number: payloadString }
    case actions.from_description:
      return { ...state, fromDescription: payloadString }
    case actions.person_title:
      return { ...state, person_title: payloadString }
    case actions.person_description:
      return { ...state, person_description: payloadString }
    case actions.to_title:
      return { ...state, to_title: payloadString }
    case actions.to_description:
      return { ...state, to_description: payloadString }
    case actions.table_header_description:
      return { ...state, table_header_description: payloadString }
    case actions.table_header_amount:
      return { ...state, table_header_amount: payloadString }
    case actions.table_header_hour:
      return { ...state, table_header_hour: payloadString }
    case actions.table_header_total:
      return { ...state, table_header_total: payloadString }
    case actions.payment_description:
      return { ...state, payment_description: payloadString }
    case actions.closing_letters:
      return { ...state, closing_letters: payloadString }
    case actions.lines_add:
      const hourPrice = state.lines.find(a => true)?.price || 80
      return {
        ...state,
        lines: [
          ...state.lines,
          {
            price: hourPrice,
            amount: 1,
            description: "Uren " + currentMonth(),
          },
        ],
      }
    case actions.line_remove:
      const lines = [...state.lines]
      lines.splice(index, 1)
      return { ...state, lines: lines }
    case actions.line_description:
      const linesD = [...state.lines]
      linesD[index].description = payloadString
      return { ...state, lines: linesD }
    case actions.line_price:
      const linesP = [...state.lines]
      linesP[index].price = payloadString ? +payloadString : null
      return { ...state, lines: linesP }
    case actions.line_amount:
      const linesA = [...state.lines]
      linesA[index].amount = payloadString ? +payloadString : null
      return { ...state, lines: linesA }
    case actions.sendMail:
      return {
        ...state,
        email: payload as {
          emailTo: string
          emailFrom: string
          message: string
        },
      }
    case actions.flip_is_paid:
      const isPaid = !(state?.isPaid ?? false)
      return { ...state, isPaid }
    case actions.set_row_key:
      const rowKey = payloadString
      return { ...state, rowKey, isSaved: true }
    case actions.set_date:
      var date = setDate(new Date(payloadString ?? state.kwitantieDate))
      return {
        ...state,
        kwitantieDate: date,
      }
    case actions.reset:
      return resetToNextMonth(state)
  }
}
