import { Button, UseToastOptions } from "@chakra-ui/react"
import React, { useCallback, useContext, useState } from "react"
import ProgressBar from "react-topbar-progress-indicator"
import AppContext, { State } from "../context/kwitantieContext"
import downloadKwitantie from "../utils/downloadKwitantie"

const DownloadKwitantieButton = ({
  toast,
}: {
  toast: (props: UseToastOptions) => void
}) => {
  const context = useContext<any>(AppContext)

  const { state, dispatch }: { state: State; dispatch: any } = context
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false)
  const callback = useCallback(() => {
    setIsDownloadingPdf(true)
    downloadKwitantie(state)
      .then(a => {
        setIsDownloadingPdf(false)
      })
      .catch(a => {
        toast({
          title: "Geen verbinding mogelijk met onze server",
          description: `Probeer het later opnieuw.`,
          status: "error",
          duration: 100000,
          isClosable: true,
        })
        setIsDownloadingPdf(false)
      })
  }, [downloadKwitantie, state])

  return (
    <>
      {isDownloadingPdf && <ProgressBar />}
      <Button
        onClick={callback}
        style={{ margin: "0.5rem " }}
        isDisabled={isDownloadingPdf}
      >
        Download pdf
      </Button>
    </>
  )
}
export default DownloadKwitantieButton
