import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import AppContext, { actions } from "../context/kwitantieContext"
import { dateFormat, dateInputFormat } from "../utils/format"

const DateEditor = () => {
  const [isInEditMode, setIsInEditMode] = useState(false)
  const { state, dispatch } = useContext(AppContext)

  const input = useRef()

  const callbackSwitch = useCallback(() => setIsInEditMode(a => !a), [])
  useEffect(() => {
    const element = input.current as any
    if (isInEditMode && element) {
      element?.focus()
    }
  }, [isInEditMode, input.current])
  if (isInEditMode) {
    return (
      <div className="focus">
        Datum:{" "}
        <input
          ref={input}
          type="date"
          onChange={a =>
            dispatch({
              type: actions.set_date,
              payloadString: a.target.value,
            })
          }
          value={dateInputFormat(state.kwitantieDate ?? new Date())}
          onBlur={callbackSwitch}
        />
      </div>
    )
  }
  return (
    <div onClick={callbackSwitch} className="focus">
      Datum: {dateFormat(state.kwitantieDate ?? new Date())}
    </div>
  )
}

export default DateEditor
