import FileSaver from 'file-saver';
import { State } from '../context/kwitantieContext';
import { serverless } from './serverless';


export default function (state: State) {
  ga("send", 'event', 'Kwitantie form', 'download')
  return postData('/api/DownloadPdf', state)
    .then(data => {
      var blob = new Blob([data], { type: "application/pdf;charset=utf-8" });
      const filename = (state?.kwitantie_number + '.pdf').replace(/ /g, '_').replace('#', '');

      FileSaver.saveAs(blob, filename);
    });
}


async function postData(url = '', data = {}) {
  // Default options are marked with *

  const response = await serverless(url, data);

  if (!response.ok) {
    ga("send", 'event', 'Kwitantie form', 'server error')
    if (!response.ok) {
      throw 'no 200 exception';
    }
  }
  return response.arrayBuffer(); // parses JSON response into native JavaScript objects
}