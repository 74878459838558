import { initialState, State } from "../context/kwitantieContext"

export function resetToNextMonth(state: State) {
    const cleanArray = Object.keys(state)
        .filter(v => typeof v === "string")
        .map(key => ({
            key,
            value: isNotClean(state[key]) ? initialState[key] : state[key],
        }))

    var cleanObj = {}
    for (var i = 0; i < cleanArray.length; ++i)
        cleanObj[cleanArray[i].key] = cleanArray[i].value

    const output = {
        //...state,
        ...cleanObj,
        lines: [...initialState.lines],
    } as State

    if (state.lines.length > 0 && output.lines.length > 0) {
        const hourPrice = state.lines[0].price
        output.lines = [
            {
                price: hourPrice,
                amount: 1,
                description: "Uren " + currentMonth(),
            },
        ]
    }

    output.number = (output.number || 1) + 1

    const posNumbering = output.kwitantie_number.indexOf("#")
    if (posNumbering >= 0) {
        output.kwitantie_number =
            output.kwitantie_number.substring(0, posNumbering + 1) + output.number
    }

    const amount = output.lines.length > 0 ? output.lines[0].amount : undefined
    if (amount) {
        output.lines[0].amount = amount
    }
    output.rowKey = undefined
    output.kwitantieDate = setDate(new Date())
    return output
}

export function setDate(date: Date) {
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
    return date
}

export function currentMonth() {
    const monthNames = [
        "januari",
        "februari",
        "maart",
        "april",
        "mei",
        "juni",
        "juli",
        "augustus",
        "september",
        "oktober",
        "november",
        "december",
    ]

    const d = new Date()
    let monthNumber = d.getMonth()
    monthNumber--
    if (monthNumber < 0) {
        monthNumber = 11
    }
    return monthNames[monthNumber]
}

export function isNotClean(input: string) {
    if (input == null) {
        return
    }

    if (input.replace) {
        input = input.replace(/\<br/, "")
        input = input.replace(/\<\/br/, "")
    }

    if (input?.indexOf) {
        return false
    }

    if (typeof input !== "string") {
        return
    }

    const f = input?.indexOf("<") >= 0

    return f
}
