const euro = new Intl.NumberFormat("nl-NL", {
  style: "currency",
  currency: "EUR",
})

export const euroFormat = (input: number) => euro.format(input);
export const dateFormat = (input: string | Date) => {
  var date = new Date(input);
  return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
};

export const dateInputFormat = (input: string | Date) => {
  var date = new Date(input);
  return `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(date.getDate())}`;
};

const addZero = (input: number) => {
  if (input < 10) {
    return "0" + input.toString();
  }
  return input.toString();
}