import { Button } from "@chakra-ui/react"
import React, { useContext, useEffect, useRef } from "react"
import ContentEditable from "react-contenteditable"
import AppContext, { actions, State } from "../context/kwitantieContext"
import { euroFormat } from "../utils/format"
import useKwitantieSave from "./../hooks/saveComponent"
import DateEditor from "./dateEditor"
import { AiOutlineDelete } from "@react-icons/all-files/ai/AiOutlineDelete";
// import { AiOutlineArrowLeft } from "@react-icons/all-files/ai/AiOutlineArrowLeft";
// import { AiOutlineArrowRight } from "@react-icons/all-files/ai/AiOutlineArrowRight";
// import { ClassNames } from "@emotion/react"


const Kwitantie = () => {
  const context = useContext<any>(AppContext)
  const { state, dispatch }: { state: State; dispatch: any } = context

  useKwitantieSave(state, dispatch)
  const firstElement = useRef()
  useEffect(() => {
    const element = firstElement.current as any
    if (element) {
      element?.focus()
    }
  }, [firstElement.current])

  return (
    <>
      {/* <div style={{ display: "flex", justifyContent: "space-between", borderTop: "1px solid #ddd", bottom: "0", position: "fixed", backgroundColor: "#fff", left: 0, width: "100vw" }} className="noPrint">
        <AiOutlineArrowLeft
          onClick={() => { }}


          className="mouseOnly" />
        <AiOutlineArrowRight
          onClick={() => { }}


          className="mouseOnly" />
      </div> */}
      <div className="page kwitantie">
        <div>
          <div style={{minWidth: "15cm"}}> 
          <div className="row">
            <ContentEditable
              html={state.kwitantieTitle}
              ref={firstElement.current}
              className="focus"
              onChange={a =>
                dispatch({
                  type: actions.kwitantie_title,
                  payloadString: a.target.value,
                })
              }
              tagName="h2"
              style={{
                color: "#5d5d5d",
                textAlign: "right",
                marginLeft: "auto",
                fontWeight: "bold",
                fontSize: "150%",
              }}
            />
          </div>
          <div className="row">
            <ContentEditable
              html={state.fromDescription}
              className="focus"
              onChange={a =>
                dispatch({
                  type: actions.from_description,
                  payloadString: a.target.value,
                })
              }
              tagName="div"
            />
            <div>
              <ContentEditable
                className="focus"
                html={state.kwitantie_number}
                onChange={a =>
                  dispatch({
                    type: actions.kwitantie_number,
                    payloadString: a.target.value,
                  })
                }
                tagName="div"
              />
              <DateEditor />
            </div>
          </div>
          <div className="row">
            <div>
              <ContentEditable
                className="focus"
                html={state.person_title}
                onChange={a =>
                  dispatch({
                    type: actions.person_title,
                    payloadString: a.target.value,
                  })
                }
                tagName="h4"
                style={{ fontWeight: "bold" }}
              />
              <ContentEditable
                className="focus"
                html={state.person_description}
                onChange={a =>
                  dispatch({
                    type: actions.person_description,
                    payloadString: a.target.value,
                  })
                }
                tagName="div"
              />
            </div>
            <div>
              <ContentEditable
                className="focus"
                html={state.to_title}
                onChange={a =>
                  dispatch({
                    type: actions.to_title,
                    payloadString: a.target.value,
                  })
                }
                tagName="h4"
                style={{ fontWeight: "bold" }}
              />
              <ContentEditable
                className="focus"
                html={state.to_description}
                onChange={a =>
                  dispatch({
                    type: actions.to_description,
                    payloadString: a.target.value,
                  })
                }
                tagName="div"
              />
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <ContentEditable
                  html={state.table_header_description}
                  onChange={a =>
                    dispatch({
                      type: actions.table_header_description,
                      payloadString: a.target.value,
                    })
                  }
                  tagName="th"
                />
                <ContentEditable
                  html={state.table_header_amount}
                  onChange={a =>
                    dispatch({
                      type: actions.table_header_amount,
                      payloadString: a.target.value,
                    })
                  }
                  tagName="th"
                />
                <ContentEditable
                  html={state.table_header_hour}
                  onChange={a =>
                    dispatch({
                      type: actions.table_header_hour,
                      payloadString: a.target.value,
                    })
                  }
                  tagName="th"
                />

                <ContentEditable
                  html={state.table_header_total}
                  onChange={a =>
                    dispatch({
                      type: actions.table_header_total,
                      payloadString: a.target.value,
                    })
                  }
                  tagName="th"
                />
                <th className="noPrint"></th>
              </tr>
            </thead>
            <tbody>
              {(state.lines ?? []).map((item, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="text"
                      name={`lines[${index}].name`}
                      value={item.description}
                      style={{ padding: "10px", width: "100%" }}
                      aria-label={`${index} naam`}
                      onChange={t =>
                        dispatch({
                          type: actions.line_description,
                          payloadString: t.target.value,
                          index,
                        })
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      style={{ padding: "10px", width: "100px" }}
                      value={item.price}
                      min="0.00"
                      max="10000.00"
                      step="0.01"
                      aria-label={`${index} prijs`}
                      onChange={t =>
                        dispatch({
                          type: actions.line_price,
                          payloadString: t.target.value,
                          index,
                        })
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      onChange={t =>
                        dispatch({
                          type: actions.line_amount,
                          payloadString: t.target.value,
                          index,
                        })
                      }
                      style={{ padding: "10px", width: "100px" }}
                      value={item.amount}
                      name={`lines[${index}].quantity`}
                      aria-label={`${index} aantal`}
                      step="0.1"
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {euroFormat(item.amount * item.price)}
                  </td>
                  <td className="noPrint" style={{ textAlign: "center" }}>

                    <AiOutlineDelete aria-label={"verwijder " + index}
                      style={{ cursor: "pointer" }}
                      title="Verwijder regel"
                      size={20}
                      onClick={() =>
                        dispatch({ type: actions.line_remove, index })
                      }
                      className="mouseOnly"/>
                    
                  </td>
                </tr>
              ))}
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td className="noPrint">&nbsp;</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colSpan={3}>&nbsp;</th>
                <th>
                  {euroFormat(
                    (state.lines ?? [])
                      .map(a => a.amount * a.price)
                      .reduce((a, b) => a + b, 0)
                  )}
                </th>
                <th className="noPrint">&nbsp;</th>
              </tr>
            </tfoot>
          </table>

          <section className="noPrint" style={{ marginTop: "1rem" }}>
            <Button
              type="button"
              onClick={() => dispatch({ type: actions.lines_add })}
            >
              toevoegen
            </Button>
          </section>
        </div>
        <div>
          <ContentEditable
            html={state.payment_description}
            className="focus"
            onChange={a =>
              dispatch({
                type: actions.payment_description,
                payloadString: a.target.value,
              })
            }
            tagName="div"
            style={{ textAlign: "center", margin: "1cm" }}
          />
          <ContentEditable
            html={state.closing_letters}
            className="focus"
            onChange={a =>
              dispatch({
                type: actions.closing_letters,
                payloadString: a.target.value,
              })
            }
            tagName="div"
            style={{ textAlign: "center", margin: "1cm" }}
          />
        </div>
        </div>
      </div>
    </>
  )
}
export default Kwitantie
