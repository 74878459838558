import React, { useState, useContext, useEffect } from "react"
import AppContext, { State, actions } from "../../context/kwitantieContext"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  UseDisclosureReturn
} from "@chakra-ui/react"


const newModal = ({ control }: { control: UseDisclosureReturn }) => {
  const context = useContext<any>(AppContext)
  const { dispatch }: { state: State; dispatch: any } = context
  return (
    <Modal isOpen={control.isOpen} onClose={control.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Nieuwe kwitantie</ModalHeader>
        <ModalCloseButton />
        <ModalBody>De vorige kwitantie raakt verloren. </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={control.onClose} mr={3}>
            Annuleer
          </Button>
          <Button
            variantColor="blue"
            onClick={() => {
              dispatch({ type: actions.reset })
              control.onClose()
            }}
          >
            Nieuwe kwitantie
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
export default newModal
