import { api } from "../settings";

export const serverless = (url: string, data: object, method?: string, accessToken?: string) => {
  const t = api + url;
  console.log(t);
  return fetch(t, {
    method: method ?? 'POST', // *GET, POST, PUT, DELETE, etc.x
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: method === "GET" ? null : JSON.stringify(data) // body data type must match "Content-Type" header
  });
}

export const serverlessAuth = (url: string, data: object, method: string, token: (options?: any) => Promise<string>) => {
  return token({
    audience: `https://kwitantie.eu.auth0.com/api/v2/`,
    scope: "read:current_user",
  }).then(token => serverless(url, data, method, token))
    .then(a => a.text())
    .then(a => a && a.length > 0 ?  JSON.parse(a) : undefined)
  
}