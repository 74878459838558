import { useAuth0 } from "@auth0/auth0-react"
import { Button, UseToastOptions } from "@chakra-ui/react"
import React, { useCallback, useContext, useState } from "react"
import ProgressBar from "react-topbar-progress-indicator"
import AppContext, { actions, State } from "../context/kwitantieContext"
import { serverless, serverlessAuth } from "../utils/serverless"

const SaveKwitantieButton = ({
  toast,
}: {
  toast: (props: UseToastOptions) => void
}) => {
  const context = useContext<any>(AppContext)
  const { getAccessTokenSilently } = useAuth0()

  const { state, dispatch }: { state: State; dispatch: any } = context
  const [isWorking, setIsWorking] = useState(false)
  const callback = useCallback(async () => {
    setIsWorking(true)

    serverlessAuth(
      "/api/SaveKwitantie",
      { ...state },
      "POST",
      getAccessTokenSilently
    )
      .then(a => {
        dispatch({
          type: actions.set_row_key,
          payloadString: a.rowKey,
        })
        setIsWorking(false)
        toast({
          title: "Succesvol opgeslagen",
          status: "success",
          duration: 5000,
          isClosable: true,
        })
      })
      .catch(a => {
        toast({
          title: "Geen verbinding mogelijk met onze server",
          description: `Probeer het later opnieuw.`,
          status: "error",
          duration: 10000,
          isClosable: true,
        })
        setIsWorking(false)
      })
  }, [serverless, state])

  return (
    <>
      {isWorking && <ProgressBar />}
      <Button
        onClick={callback}
        style={{ margin: "0.5rem" }}
        isDisabled={isWorking || state.isSaved}
      >
        {state.isSaved ? "Opgeslagen" : "Opslaan"}
      </Button>
    </>
  )
}
export default SaveKwitantieButton
