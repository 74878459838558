import AES from "crypto-js/aes"
import Utf8 from "crypto-js/enc-utf8"



function key(whenNotExistCreate?: boolean){
    let key = localStorage.getItem('kwitantieKey')
    if(!key && whenNotExistCreate){
        key = generateId(150).toString();
        localStorage.setItem('kwitantieKey' , key);
    }

    return key;
}

export function encrypt(input: string){
    return AES.encrypt(input,  key(true));
      
}

export function decrypt(encrypted: string){

    const k = key(false)
    if(!k){
        // maybe not encrypted yet
        return encrypted;
    }
    var decrypted = AES.decrypt(encrypted,  k);
    return (decrypted.toString(Utf8));   
}


function dec2hex(dec) {
    return dec.toString(16).padStart(2, "0")
  }
  
  // generateId :: Integer -> String
  function generateId (len : number) {
    var arr = new Uint8Array((len || 40) / 2)
    window.crypto.getRandomValues(arr)
    return Array.from(arr, dec2hex).join('')
  }
  