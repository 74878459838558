import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { BehaviorSubject, interval } from "rxjs";
import { debounce, filter, skip } from "rxjs/operators";
import { actions, State } from "../context/kwitantieContext";
import { serverlessAuth } from "../utils/serverless";

const useKwitantieSave = (state: State, dispatch: any,) => {
  const [save$] = useState(new BehaviorSubject(undefined))
  const { isAuthenticated } = useAuth0();
  const saveToApi = async (s: State) => {

    serverlessAuth("/api/SaveKwitantie", { ...s }, "POST", getAccessTokenSilently).then(a => {
      dispatch({
        type: actions.set_row_key,
        payloadString: a.rowKey,
      })
    });
  };

  useEffect(() => {

    if (!isAuthenticated) {
      return
    }

    save$.pipe(filter(a => !!a), debounce(a => interval(5 * 1000)), skip(1)).subscribe(saveToApi);
    return () => {
      const data = save$.value;
      console.log(data);
      if (data && !data.isSaved) {
        saveToApi(data);
      }
      save$.complete();
    };


  }, [isAuthenticated, save$])

  useEffect(() => {
    if (!state.isSaved) {
      save$.next(state)
    }
  }, [state]);

  const { getAccessTokenSilently } = useAuth0()

};
export default useKwitantieSave;